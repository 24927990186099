html {
  font-family: Inter, sans-serif;
}

@keyframes pop {
  0% {
    transform: scale(1);
    box-shadow: var(rgba(63, 63, 68, 0.05) 0px 0px 0px 1px, rgba(34, 33, 81, 0.15) 0px 1px 3px 0px);
  }
  100% {
    transform: scale(1.05);
    box-shadow: rgba(63, 63, 68, 0.05) 0px 0px 0px 1px, -1px 0 15px 0 rgba(34, 33, 81, 0.01),
      0px 15px 15px 0 rgba(34, 33, 81, 0.25);
  }
}

::placeholder {
  opacity: 1 !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* INFO: this is necessary for Select dropdown. Because of the getPopupContainer functionality, dropdown section is not a child of CFSelect. */
.ant-select-item-option-content {
  white-space: break-spaces !important;
}

/* Start: This is for error over ride, We have override antd custom border and error message styles */
.ant-form-item-explain-error {
  font-weight: 600;
}
.ant-input-status-error,
.ant-picker-status-error {
  border-width: 2px !important;
}

.ant-select-status-error {
  .ant-select-selector {
    border-width: 2px !important;
  }
}
/* End : This is for error over ride, We have override antd custom border and error message styles */

/* ------------------------------ start application specific style override ---------------------------------------------- */

/* :root {
  --disabledTextColor: rgba(0, 0, 0, 0.8);
  --disabledBorderColor: #d9d9d9;
  --disabledBg: #f5f5f5;
} */

body {
  font-feature-settings: 'tnum', 'tnum';
  background-color: #fff;
  color: #000000d9;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  margin: 0;
}

/* #application_container .ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: var(--disabledTextColor) !important;
  border: 1px solid var(--disabledBorderColor) !important;
  background: 1px solid var(--disabledBg) !important;
} */

/* .ant-modal-wrap .ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: var(--disabledTextColor) !important;
  border: 1px solid var(--disabledBorderColor) !important;
  background: 1px solid var(--disabledBg) !important;
} */

/* #application_container .ant-input[disabled],
.ant-modal-wrap .ant-input[disabled] {
  color: var(--disabledTextColor) !important;
} */

/* #application_container .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-modal-wrap .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  .ant-select-selection-item {
    color: var(--disabledTextColor) !important;
  }
  color: var(--disabledTextColor) !important;
} */

/* #application_container .ant-radio-button-wrapper-disabled,
.ant-modal-wrap .ant-radio-button-wrapper-disabled {
  color: var(--disabledTextColor) !important;
} */

/* #application_container .ant-picker-input > input[disabled],
.ant-modal-wrap .ant-picker-input > input[disabled] {
  color: var(--disabledTextColor) !important;
} */

/* ------------------------------ End application specific style override ---------------------------------------------- */
